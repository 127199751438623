<i18n lang="json">
{
  "en": {
    "sites": "{count} sites",
    "distance": "{distance} km away",
    "campground": "Campground"
  },
  "fr": {
    "sites": "{count} sites",
    "distance": "à {distance} km",
    "campground": "Camping"
  }
}
</i18n>

<template>
  <div class="card-campground">
    <LazyImageCarousel
      :images="campground.photos ?? []"
      :item-id="campground.id!"
      :show-navigation-arrows="isLargeScreen"
      :href="campgroundLink"
      :target="campgroundTarget"
      :eager-loading="loadFirstImage"
      :high-priority-first-image="isLargeScreen ? true : position <= 2"
      class="mb-4"
      @click="handleCardClick"
    />

    <div :class="['caption bg-white', { 'px-4 pb-4 ': padContent }]">
      <div class="flex justify-between items-center mb-1 gap-2">
        <LinkButton
          :href="campgroundLink"
          :target="campgroundTarget"
          class="overflow-hidden whitespace-nowrap text-ellipsis text-left"
          @click.prevent="handleCardClick"
          :title="campground.name"
        >
          {{ campground.name }}
        </LinkButton>
        <CampgroundHookups :campground="campground" />
      </div>
      <LinkButton
        :href="campgroundLink"
        :target="campgroundTarget"
        class="text-left caption"
        @click.prevent="handleCardClick"
      >
        <div class="flex gap-2 items-center mb-1">
          <div class="text-primary-350">{{ t('campground') }}</div>
          <template v-if="campground.nightlyRates || campground.numberOfSites">
            <div>•</div>
            <CampgroundRates v-if="campground.nightlyRates" :rate="campground.nightlyRates" />
            <span v-if="campground.numberOfSites">({{ t('sites', { count: campground.numberOfSites }) }})</span>
          </template>
        </div>
        <div class="flex gap-1 mb-4 text-primary-350">
          <span>{{ campground.placeName }}</span>
          <span v-if="campground.distance">
            ({{
              t('distance', {
                distance: Math.round(campground.distance)
              })
            }})
          </span>
        </div>
      </LinkButton>
      <CardCampgroundAmenities :campground="campground" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Campground } from '~/types/api'
const props = defineProps<{
  listId: string
  position: number
  campground: Campground
  padContent?: boolean
  loadFirstImage?: boolean
  skipSchemaOrg?: boolean
}>()

const handleCardClick = async () => {
  segmentTrack('Product Clicked', {
    list_id: props.listId,
    brand: props.campground.brand ?? 'none',
    category: 'campground',
    name: props.campground.name ?? 'Unknown',
    product_id: props.campground.id ?? 'Unknown',
    url: campgroundLink.value,
    currency: props.campground.countryCode ? getCurrencyCode(props.campground.countryCode) : undefined,
    image_url: props.campground.photos?.at(0),
    position: props.position
  })

  await navigateTo(campgroundLink.value, { open: { target: campgroundTarget.value ?? '_self' } })
}

const { t } = useI18n()
const isLargeScreen = useIsLargeScreen()

const campgroundLink = computed(() =>
  getLocalePath({ name: 'campground-slug', params: { slug: props.campground.slug ?? props.campground.id } })
)
const campgroundTarget = computed(() => (isLargeScreen.value ? '_blank' : undefined))

const schemaOrg = useSchemaOrg()
useJsonld(() => {
  if (!props.campground || props.skipSchemaOrg) return
  return schemaOrg.campgroundAPIToSchemaOrgCampground(props.campground)
})
</script>

<style lang="postcss" scoped>
@layer BaseComponents {
  .card-campground {
    @apply bg-white rounded-2xl overflow-hidden;
  }
}
</style>
