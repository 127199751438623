<i18n lang="json">
{
  "en": {
    "beach": "Beach",
    "boatLaunch": "Boat Launch",
    "cableTv": "Cable TV",
    "dumpStation": "Dump stations",
    "firewood": "Firewood",
    "fishing": "Fishing",
    "generalStore": "General store",
    "grills": "Grills",
    "showers": "Showers",
    "hiking": "Hiking",
    "internet": "Internet",
    "petFriendly": "Pet friendly",
    "laundry": "Laundry",
    "playground": "Playground",
    "pool": "Pool",
    "propane": "Propane",
    "tables": "Tables",
    "toilets": "Toilets"
  },
  "fr": {
    "beach": "Plage",
    "boatLaunch": "Rampe de mise à l'eau",
    "cableTv": "TV par câble",
    "dumpStation": "Stations de vidange",
    "firewood": "Bois de chauffage",
    "fishing": "Pêche",
    "generalStore": "Magasin général",
    "grills": "Grils disponibles",
    "showers": "Douches",
    "hiking": "Randonnée",
    "internet": "Internet",
    "petFriendly": "Animaux acceptés",
    "laundry": "Buanderie",
    "playground": "Terrain de jeux",
    "pool": "Piscine",
    "propane": "Propane",
    "tables": "Tables",
    "toilets": "Toilettes"
  }
}
</i18n>

<template>
  <div
    v-if="amenityComponents.length"
    class="flex gap-2 items-center overflow-hidden flex-wrap max-h-8 mb-2 bg-primary-50 py-2 px-3 rounded font-bold w-fit"
  >
    <component
      v-for="(component, index) in amenityComponents"
      :key="index"
      :is="component.component"
      v-bind="component.props"
    />
  </div>
</template>

<script lang="ts" setup>
import IconAmenityInternetComponent from '~/components/icon/amenity/internet.vue'
import IconAmenityDumpStationComponent from '~/components/icon/amenity/dump-station.vue'
import IconAmenityPoolComponent from '~/components/icon/amenity/pool.vue'
import IconAmenityGeneralStoreComponent from '~/components/icon/amenity/general-store.vue'
import IconAmenityBeachComponent from '~/components/icon/amenity/beach.vue'
import IconAmenityPropaneComponent from '~/components/icon/amenity/propane.vue'
import IconAmenityPetFriendlyComponent from '~/components/icon/amenity/pet-friendly.vue'
import IconAmenityShowerComponent from '~/components/icon/amenity/shower.vue'
import IconAmenityLaundryComponent from '~/components/icon/amenity/laundry.vue'
import IconAmenityToiletComponent from '~/components/icon/amenity/toilet.vue'
import IconAmenityTableComponent from '~/components/icon/amenity/table.vue'
import IconAmenityFirewoodComponent from '~/components/icon/amenity/firewood.vue'
import IconAmenityGrillsComponent from '~/components/icon/amenity/grills.vue'
import IconAmenityHikingComponent from '~/components/icon/amenity/hiking.vue'
import IconAmenityPlaygroundComponent from '~/components/icon/amenity/playground.vue'
import IconAmenityFishingComponent from '~/components/icon/amenity/fishing.vue'
import IconAmenityCableTvComponent from '~/components/icon/amenity/cable-tv.vue'
import IconAmenityBoatLaunchComponent from '~/components/icon/amenity/boat-launch.vue'
import IconAmenityCounterComponent from '~/components/icon/amenity/counter.vue'
import type { Campground } from '~/types/api'

const props = defineProps<{
  campground: Campground
}>()

const { t } = useI18n()

const amenityComponents = computed(() => {
  const components = []
  const extraAmenities = []
  const maxAmenities = 5
  const classes = ['size-4']

  const amenitySet = getAmenities(props.campground.amenities)

  if (amenitySet.has('HasInternet')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityInternetComponent,
        props: {
          tooltip: t('internet'),
          class: classes
        }
      })
    } else {
      extraAmenities.push(t('internet'))
    }
  }

  if (amenitySet.has('HasDumpStation')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityDumpStationComponent,
        props: {
          class: classes,
          tooltip: t('dumpStation')
        }
      })
    } else {
      extraAmenities.push(t('dumpStation'))
    }
  }

  if (amenitySet.has('HasPool')) {
    if (components.length <= maxAmenities) {
      components.push({
        props: {
          component: IconAmenityPoolComponent,
          class: classes,
          tooltip: t('pool')
        }
      })
    } else {
      extraAmenities.push(t('pool'))
    }
  }

  if (amenitySet.has('HasGeneralStore')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityGeneralStoreComponent,
        props: {
          class: classes,
          tooltip: t('generalStore')
        }
      })
    } else {
      extraAmenities.push(t('generalStore'))
    }
  }

  if (amenitySet.has('HasBeach')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityBeachComponent,
        props: {
          class: classes,
          tooltip: t('beach')
        }
      })
    } else {
      extraAmenities.push(t('beach'))
    }
  }

  if (amenitySet.has('HasPropane')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityPropaneComponent,
        props: {
          class: classes,
          tooltip: t('propane')
        }
      })
    } else {
      extraAmenities.push(t('propane'))
    }
  }

  if (amenitySet.has('IsPetFriendly')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityPetFriendlyComponent,
        props: {
          class: classes,
          tooltip: t('petFriendly')
        }
      })
    } else {
      extraAmenities.push(t('petFriendly'))
    }
  }

  if (amenitySet.has('HasShowers')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityShowerComponent,
        props: {
          class: classes,
          tooltip: t('showers')
        }
      })
    } else {
      extraAmenities.push(t('showers'))
    }
  }

  if (amenitySet.has('HasLaundryFacilities')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityLaundryComponent,
        props: {
          class: classes,
          tooltip: t('laundry')
        }
      })
    } else {
      extraAmenities.push(t('laundry'))
    }
  }

  if (amenitySet.has('HasToilets')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityToiletComponent,
        props: {
          class: classes,
          tooltip: t('toilets')
        }
      })
    } else {
      extraAmenities.push(t('toilets'))
    }
  }

  if (amenitySet.has('HasTables')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityTableComponent,
        props: {
          class: classes,
          tooltip: t('tables')
        }
      })
    } else {
      extraAmenities.push(t('tables'))
    }
  }

  if (amenitySet.has('HasFirewood')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityFirewoodComponent,
        props: {
          class: classes,
          tooltip: t('firewood')
        }
      })
    } else {
      extraAmenities.push(t('firewood'))
    }
  }

  if (amenitySet.has('HasGrills')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityGrillsComponent,
        props: {
          class: classes,
          tooltip: t('grills')
        }
      })
    } else {
      extraAmenities.push(t('grills'))
    }
  }

  if (amenitySet.has('HasHiking')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityHikingComponent,
        props: {
          class: classes,
          tooltip: t('hiking')
        }
      })
    } else {
      extraAmenities.push(t('hiking'))
    }
  }

  if (amenitySet.has('HasPlayground')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityPlaygroundComponent,
        props: {
          class: classes,
          tooltip: t('playground')
        }
      })
    } else {
      extraAmenities.push(t('playground'))
    }
  }

  if (amenitySet.has('HasFishing')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityFishingComponent,
        props: {
          class: classes,
          tooltip: t('fishing')
        }
      })
    } else {
      extraAmenities.push(t('fishing'))
    }
  }

  if (amenitySet.has('HasCableTV')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityCableTvComponent,
        props: {
          class: classes,
          tooltip: t('cableTv')
        }
      })
    } else {
      extraAmenities.push(t('cableTv'))
    }
  }

  if (amenitySet.has('HasBoatLaunch')) {
    if (components.length <= maxAmenities) {
      components.push({
        component: IconAmenityBoatLaunchComponent,
        props: {
          class: classes,
          tooltip: t('boatLaunch')
        }
      })
    } else {
      extraAmenities.push(t('boatLaunch'))
    }
  }

  if (extraAmenities.length) {
    const removedComponent = components.pop()!
    extraAmenities.unshift(removedComponent.props.tooltip)
    components.push({
      component: IconAmenityCounterComponent,
      props: {
        class: 'text-xs',
        tooltip: extraAmenities.sort().join('<br />'),
        counter: extraAmenities.length
      }
    })
  }

  return components
})
</script>
