import type { Place, PlaceAncestry, PlaceType } from '~/types/api'

type AncestryMap = {
  [key in PlaceType]?: Place
}

export default (ancestry?: PlaceAncestry[] | null) => {
  if (!ancestry) return {}

  return ancestry.reduce((map, place) => {
    if (place.placeType) map[place.placeType] = place
    return map
  }, {} as AncestryMap)
}
