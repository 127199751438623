<i18n lang="json">
{
  "en": {
    "eletricalHookup": "Electric hookup",
    "sewerHookup": "Sewer hookup",
    "waterHookup": "Water hookup"
  },
  "fr": {
    "eletricalHookup": "Branchement électricité",
    "sewerHookup": "Branchement d'égout",
    "waterHookup": "Branchement d'eau"
  }
}
</i18n>

<template>
  <div class="flex gap-1 items-center">
    <IconAmenityElectrical
      v-tooltip="{ content: t('eletricalHookup'), triggerOn: tooltipTrigger }"
      :class="['size-5', { 'text-primary-200': !amenitySet.has('HasEletricalHookup') }]"
    />
    <IconAmenityWater
      v-tooltip="{ content: t('waterHookup'), triggerOn: tooltipTrigger }"
      :class="['size-5', { 'text-primary-200': !amenitySet.has('HasWaterHookup') }]"
    />
    <IconAmenitySewer
      v-tooltip="{ content: t('sewerHookup'), triggerOn: tooltipTrigger }"
      :class="['size-5', { 'text-primary-200': !amenitySet.has('HasSewerHookup') }]"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Campground } from '~/types/api'

const props = defineProps<{
  campground: Campground
}>()

const { t } = useI18n()
const isLargeScreen = useIsLargeScreen()

const tooltipTrigger = computed(() => (isLargeScreen.value ? 'hover' : 'click'))

const amenitySet = computed(() => getAmenities(props.campground.amenities))
</script>
