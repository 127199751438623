import type { Campground, NightlyRate } from '~/types/api'
import type { CampgroundSchemaOrg } from '~/types/schema-org/schema-org-types'

export default () => {
  const campgroundRateMap = {
    Free: "$",
    Low: "$",
    Lowest: "$$",
    Medium: "$$$",
    High: "$$$$",
    Highest: "$$$$$"
  } as Record<NightlyRate, string>

  function getRate(rate: NightlyRate | undefined | null) {
    return rate ? campgroundRateMap[rate] : undefined
  }

  function campgroundAPIToSchemaOrgCampground(
    campground: Campground
  ): CampgroundSchemaOrg {
    const rate = getRate(campground.nightlyRates)
    const ancestry = getAncestry(campground.ancestry)
    return {
      '@context': 'https://schema.org/',
      '@type': 'Campground',
      name: campground.name ?? '',
      address: {
        '@type': 'PostalAddress',
        streetAddress: campground.streetAddress,
        postalCode: campground.postalCode,
        addressLocality: ancestry.City?.name,
        addressRegion: ancestry.Region?.name,
        addressCountry: ancestry.Country?.code
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: campground.latitude,
        longitude: campground.longitude
      },
      latitude: campground.latitude,
      longitude: campground.longitude,
      brand: campground.brand,
      url: campground.websiteLink ?? undefined,
      telephone: campground.phoneNumber ?? undefined,
      numberOfRooms: campground.numberOfSites ?? undefined,
      petsAllowed: campground.amenities?.includes('IsPetFriendly'),
      priceRange: rate,
      photo: campground.photos?.at(0),
      image: campground.photos?.at(0)
    }
  }

  return {
    getRate,
    campgroundAPIToSchemaOrgCampground
  };
};
