<i18n lang="json">
{
  "en": {
    "Free": "Free",
    "Lowest": "$",
    "Low": "$$",
    "Medium": "$$$",
    "High": "$$$$",
    "Highest": "$$$$$"
  },
  "fr": {
    "Free": "Gratuit",
    "Lowest": "$",
    "Low": "$$",
    "Medium": "$$$",
    "High": "$$$$",
    "Highest": "$$$$$"
  }
}
</i18n>

<template>
  <span class="caption text-primary-350">
    <template v-if="rateLabel">
      <span class="text-primary font-bold">{{ rateLabel }}</span
      ><span>{{ rateLabelSuffix }}</span>
    </template>
    <IconCircleQuestionMark v-else />
  </span>
</template>

<script lang="ts" setup>
const props = defineProps<{
  rate: string
}>()

const { t, te } = useI18n()

const rateLabel = computed(() => (props.rate != null && te(props.rate) ? t(props.rate) : null))
const rateLabelSuffix = computed(() =>
  rateLabel.value != null && /^[$]+$/.test(rateLabel.value) && rateLabel.value.length < 5
    ? '$'.repeat(5 - rateLabel.value.length)
    : ''
)
</script>
